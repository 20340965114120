<template>
  <v-card flat>
    <b-row>
      <b-col :md="4">
        <b-form-group
          id="item-subcs-group"
          v-bind:label="$t('FORM_LABELS.SUBCONTRACTOR')"
          label-for="item-subc-input"
        >
          <FormSelect
            id="item-subcs-input"
            type="select"
            name="itemSubcs"
            clearable
            :options="subcontractors"
            v-model="form.subcontractorId"
            @input="fetchSubcontractorLocations($event)"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-subc-locations-group"
          v-bind:label="$t('FORM_LABELS.UNLOADING')"
          label-for="item-subc-locations-input"
        >
          <FormSelect
            id="item-subc-locations-input"
            type="select"
            name="itemSubcLocations"
            clearable
            :options="subcontractorLocations"
            v-model="form.subcLocationId"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="invoice-start-date"
          :label="$t('FORM_LABELS.FROM')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            id="invoice-included-date-from"
            type="text"
            name="inv_date_from"
            :i18n="{ label: '', placeholder: '' }"
            :validations="'validations: {required: true, minLength: 0}'"
            :validateState="validateState('invDateFrom')"
            max-today
            v-model="form.invDateFrom"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-bill-number-group"
          :label="$t('FORM_LABELS.TO')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            id="invoice-included-date-to"
            type="date"
            name="inv_date_to"
            :i18n="{ label: '', placeholder: '' }"
            :validations="'validations: {required: false, minLength: 0}'"
            :validateState="validateState('invDateTo')"
            max-today
            v-model="form.invDateTo"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
      <div v-if="canShowBolWork" class="text-right mt-n5 col-md-12">
        <button class="btn btn-success" v-on:click="searchBolWork">
          {{ $t("LABELS.SHOW_BILLS_OF_LADING_WORK") }}
        </button>
      </div>
    </b-row>
    <b-row class="justify-content-between" v-if="bolWorkResults.length > 0">
      <b-col v-if="!form.noInvoice" :md="6">
        <b-form-group
          id="item-bill-number-group"
          :label="$t('FORM_LABELS.INVOICE_NUMBER')"
          label-for="item-bill-number-input"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-number-input"
              type="text"
              name="bill_number"
              :i18n="{
                label: 'FORM_LABELS.INVOICE_NUMBER',
                placeholder: $t('FORM_LABELS.INVOICE_NUMBER'),
              }"
              v-model="form.invoiceNumber"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>

      <b-col v-if="!form.noInvoice" :md="6">
        <b-form-group
          id="item-bill-number-group"
          :label="$t('FORM_LABELS.DATE')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            id="invoice-date-input"
            type="text"
            max-today
            v-model="form.invoiceDate"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
      <b-col :md="12">
        <b-label class="ml-0 pl-0">
          {{ $t("FORM_LABELS.ADD_NO_INVOICE") }}
          <FormSwitch
            id="no_invoice"
            type="checkbox"
            name="no_invoice"
            v-model="form.noInvoice"
          ></FormSwitch>
        </b-label>
      </b-col>
      <b-col :md="4">
        <div class="text-right">
          <p class="pt-8"><b>Стойност на фактурата:</b></p>
        </div>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VALUE_NO_VAT')"
          label-for="item-bill-total-value"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-total-value"
              type="number"
              name="bill_total_value"
              disabled
              :i18n="{
                label: 'FORM_LABELS.VALUE_NO_VAT',
                placeholder: $t('FORM_LABELS.VALUE_NO_VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="form.totalNoVat"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="1">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VAT')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormSwitch
              id="bill-invoice-vat-switch"
              type="checkbox"
              :name="'vat-switch'"
              :i18n="{
                label: 'FORM_LABELS.VAT',
                placeholder: $t('FORM_LABELS.VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="vat"
              @input="switchVat($event)"
            ></FormSwitch>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VALUE_VAT')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-vat"
              type="number"
              name="vat"
              disabled
              :i18n="{
                label: 'FORM_LABELS.VAT',
                placeholder: $t('FORM_LABELS.VALUE_VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="form.vat"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VALUE_WITH_VAT')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-w-vat"
              type="number"
              name="withVat"
              disabled
              :i18n="{
                label: 'FORM_LABELS.VALUE_WITH_VAT',
                placeholder: $t('FORM_LABELS.VALUE_WITH_VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="form.totalWithVat"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <BillOfLadingWorkTable
      v-if="bolWorkResults.length > 0 || bolWorkLoading"
      :form="form"
      :items="bolWorkResults"
      :selected-bol-work="form.bolWork"
      :loading="bolWorkLoading"
      price-key="materialPrice"
      @select="handleTableSelect"
      @price:input="handlePriceInput"
      @price:total="handlePriceTotal"
    ></BillOfLadingWorkTable>
  </v-card>
</template>

<script>
import { FETCH_SUBCONTRACTORS } from "@/modules/subcontractors/store/subcontractor.module";
import { SEARCH_BILL_OF_LADING_MATERIALS } from "@/modules/bill-of-lading/store/bill-of-lading.module";
import { FETCH_SUBCONTRACTOR_LOCATIONS } from "@/modules/subcontractorslocation/store/location.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "../../../view/content/forms/components/FormSelect";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import BillOfLadingWorkTable from "../../subcontractor-expenses/pages/BillOfLadingWorkTable";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

export default {
  name: "SubcontractorExpensesMaterialsForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormSelect,
    FormInput,
    FormSwitch,
    FormDatepicker,
    BillOfLadingWorkTable,
  },
  props: {
    form: Object,
  },
  validations() {
    const tmpValidationObject = {
      form: {
        subcontractorId: this.setItemValidations({
          required: true,
        }),
        subcLocationId: this.setItemValidations({
          required: true,
        }),
        invDateFrom: this.setItemValidations({
          required: true,
          minLength: 2,
        }),
        invDateTo: this.setItemValidations({
          required: true,
          minLength: 2,
        }),
      },
    };

    return tmpValidationObject;
  },
  data() {
    return {
      vat: true,
      vatValue: 0.2,
      subcontractors: [],
      subcontractorLocations: [],
      bolWorkResults: [],
      servicesSum: [],
      servicesMeasureCount: [],
      bolWorkLoading: false,
      options: [],
    };
  },
  computed: {
    canShowBolWork() {
      return (
        this.form.subcontractorId &&
        this.form.subcLocationId &&
        this.form.invDateFrom &&
        this.form.invDateTo
      );
    },
  },
  mounted() {
    this.$store
      .dispatch(
        FETCH_SUBCONTRACTORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
          onlyMaterial: true,
        })
      )
      .then((data) => {
        this.subcontractors = data.data.items;
        if (this.form.subcontractorId) {
          this.fetchSubcontractorLocations(this.form.subcontractorId);
        }
      });
    if (
      this.form.subcontractorId &&
      this.form.subcLocationId &&
      this.form.invDateFrom &&
      this.form.invDateTo
    ) {
      this.searchBolWork();
    }
  },
  watch: {
    "form.subcontractorId": {
      handler() {
        this.resetBolWork();
      },
    },
    "form.subcLocationId": {
      handler() {
        this.resetBolWork();
      },
    },
    "form.invDateFrom": {
      handler() {
        this.resetBolWork();
      },
    },
    "form.invDateTo": {
      handler() {
        this.resetBolWork();
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    switchVat(ev) {
      this.vatValue = ev ? 0.2 : 0;
      this.totalQty();
    },
    totalQty() {
      const noVat = parseFloat(this.form.totalNoVat);
      const vat = noVat * this.vatValue;
      const withVat = noVat + vat;

      this.form.totalWithVat = withVat.toFixed(2);
      this.form.vat = vat.toFixed(2);
    },
    searchBolWork() {
      this.bolWorkLoading = true;
      let params = {
        dateFrom: this.form.invDateFrom,
        dateTo: this.form.invDateTo,
        subcLocationId: this.form.subcLocationId,
        withDefaultMaterialPrice: true,
      };

      if (this.form.id) {
        params.invoiceId = this.form.id;
      }

      this.$store
        .dispatch(
          SEARCH_BILL_OF_LADING_MATERIALS,
          this.$url.transformParams(params)
        )
        .then((data) => {
          if (data.data && data.data.length > 0) {
            this.bolWorkResults = data.data;
          } else {
            this.bolWorkResults = [];
            this.$notify({
              group: "notify",
              type: "error",
              title: "<i class='flaticon-close'></i> Warning",
              text: this.$t("ALERTS.NO_DATA"),
            });
          }
          this.bolWorkLoading = false;
        });
    },
    fetchSubcontractorLocations(id) {
      this.subcontractorLocations = [];
      this.$store
        .dispatch(
          FETCH_SUBCONTRACTOR_LOCATIONS,
          this.$url.transformParams({
            onlySelectValues: true,
            selectValueKey: "location_name",
            subcontractorId: id,
          })
        )
        .then((data) => {
          this.subcontractorLocations = data.data.items;
          this.form.subcLocationId = this.subcontractorLocations.find(
            (l) => l.id === this.form.subcLocationId
          )
            ? this.form.subcLocationId
            : null;
        });
    },
    handleTableSelect(items) {
      this.form.bolWork = items;
    },
    handlePriceInput(id, value) {
      let bolw = this.bolWorkResults.find((b) => b.id === id);
      bolw.materialPrice = value;
      let bolFormW = this.form.bolWork.find((bw) => bw.id === id);
      if (bolFormW) {
        bolFormW.materialPrice = value;
      }
    },
    handlePriceTotal(value) {
      this.form.totalNoVat = value;
      this.totalQty();
    },
    resetBolWork() {
      this.bolWorkResults = [];
      this.form.bolWork = [];
    },
  },
};
</script>

<style type="text/css">
.input-lg input {
  font-size: 14px;
  font-weight: bold;
}
.vs__dropdown-toggle {
  background-color: #fff;
}
#item-bol-list-group label {
  font-weight: bold;
  font-size: 15px;
}

.sort_div_holder {
  position: relative;
}
.sort_div {
  display: inline-block;
  padding: 16px 10px;
}
.sort_div_filter {
  position: absolute;
  top: 8px;
  right: -34px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 13px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > th.sortable {
  position: relative;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
}
.v-data-table-header__icon {
  height: 1px !important;
  float: right !important;
  top: -27px !important;
  right: -3px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
