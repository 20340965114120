<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link
            :to="{ name: 'list-subcontractor-expenses-materials' }"
            >{{ $t("MENU.ITEM.EXP.SUB_EXPENSES_MATERIALS") }}</router-link
          >
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSucontractorExpense"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <SubcontractorExpensesMaterialsForm
          ref="SubcontractorExpensesMaterialsForm"
          :form="form"
        ></SubcontractorExpensesMaterialsForm>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSucontractorExpense"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_SUBCONTRACTORS_EXPENSE_MATERIALS } from "@/modules/subcontractor-expenses-materials/store/subcontractor-expenses-materials.module.js";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import SubcontractorExpensesMaterialsForm from "./SubcontractorExpensesMaterialsForm.vue";

export default {
  name: "SubcontractorExpensesMaterialsAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    SubcontractorExpensesMaterialsForm,
  },
  data() {
    return {
      errors: [],
      form: {
        invoiceNumber: null,
        invoiceDate: null,
        subcontractorId: null,
        subcLocationId: null,
        invDateFrom: null,
        invDateTo: null,
        qty: 1,
        totalNoVat: "0.00",
        totalWithVat: "0.00",
        vat: "0.00",
        noInvoice: false,
        bolWork: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.EXP.SUB_EXPENSES_MATERIALS"),
        route: { name: "list-subcontractor-expenses-materials" },
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") },
    ]);
  },
  methods: {
    onSave(next) {
      let vm = this;

      this.$refs.SubcontractorExpensesMaterialsForm.$v.form.$touch();
      if (this.$refs.SubcontractorExpensesMaterialsForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(SAVE_SUBCONTRACTORS_EXPENSE_MATERIALS, this.form)
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-subcontractor-expenses-materials",
              params: { id: itemEdit.id },
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-subcontractor-expenses-materials" });
          }
          if (next == "exit") {
            this.$router.push({
              name: "list-subcontractor-expenses-materials",
            });
          }
        })
        .catch((response) => {
          this.errors = this.$errors.format(response.data.errors);
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>
